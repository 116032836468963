define("discourse/plugins/discourse-discord-datastore/discourse/controllers/discord", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/lib/icon-library", "discourse/lib/plugin-api", "@ember/controller"], function (_exports, _ajax, _ajaxError, _iconLibrary, _pluginApi, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    init() {
      this._super();
      let discord_icon = (0, _iconLibrary.iconNode)('fab-discord');
      this.set('is_staff', false);
      (0, _pluginApi.withPluginApi)('0.8.13', api => {
        var user = api.getCurrentUser();
        for (var i = 0; i < user.groups.length; i++) {
          if (user.groups[i].name == "staff") {
            this.set('is_staff', true);
          }
        }
      });
      this.set('current_page', 1);
      this.set('filter_channel', "");
      this.set("discord_username", "");
      this.fetchID();
      this.fetchRanks();
      this.fetchChannels();
      this.fetchMessages();
    },
    fetchID() {
      this.set('id_loaded', false);
      this.set('discord_id', "");
      (0, _ajax.ajax)("/discord/users.json?user_id=me").then(result => {
        if (result.discord_users.length > 0) {
          this.set('discord_id', result.discord_users[0].id);
          this.set("discord_username", "@" + result.discord_users[0].tag);
        }
        this.set('id_loaded', true);
      }).catch(_ajaxError.popupAjaxError);
    },
    fetchRanks() {
      (0, _ajax.ajax)("/discord/ranks.json?user_id=me").then(result => {
        this.set('ranks', result.discord_ranks);
      }).catch(_ajaxError.popupAjaxError);
    },
    fetchChannels() {
      this.set('channels_loaded', false);
      (0, _ajax.ajax)("/discord/channels.json?user_id=me").then(result => {
        this.set('channels', result.discord_channels);
        this.set('channels_loaded', true);
      }).catch(_ajaxError.popupAjaxError);
    },
    fetchMessages() {
      var params = "&page=" + (this.current_page - 1).toString();
      if (this.filter_channel.length > 0) {
        params = params + "&channel=" + this.filter_channel;
      }
      this.set('messages_loaded', false);
      (0, _ajax.ajax)("/discord/messages.json?user_id=me" + params).then(result => {
        this.set('messages', result.discord_messages);
        this.set('stats', result.stats);
        this.set('messages_loaded', true);
      }).catch(_ajaxError.popupAjaxError);
    },
    actions: {
      filterChannel(channel_id) {
        this.set('current_page', 1);
        this.set('filter_channel', channel_id);
        this.fetchMessages();
      },
      nextMessagePage() {
        this.set('current_page', this.current_page + 1);
        this.fetchMessages();
      },
      prevMessagePage() {
        this.set('current_page', Math.max(this.current_page - 1, 1));
        this.fetchMessages();
      },
      collectRank(badge_id) {
        (0, _ajax.ajax)('/discord/badge_collect.json?badge=' + badge_id.toString()).then(result => {
          if (result.result == "success") {
            for (let i = 0; i < this.ranks.length; i++) {
              if (this.ranks[i]["badge"] == badge_id) {
                this.ranks[i].have = true;
              }
            }
          }
        }).catch(_ajaxError.popupAjaxError);
      }
    }
  });
});