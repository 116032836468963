define("discourse/plugins/discourse-discord-datastore/discourse/controllers/admin-discord", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/lib/icon-library", "@ember/controller"], function (_exports, _ajax, _ajaxError, _iconLibrary, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    init() {
      this._super();
      let discord_icon = (0, _iconLibrary.iconNode)('fab-discord');
      this.set('current_page', 1);
      this.set('filter_channel', "");
      this.set('searched_user_id', "");
      this.set('searched_username', "");
      this.fetchRanks();
      this.fetchChannels();
      this.fetchMessages();
    },
    fetchRanks() {
      (0, _ajax.ajax)("/discord/ranks.json").then(result => {
        for (let i = 0; i < result.discord_ranks.length; i++) {
          result.discord_ranks[i]["have"] = true;
        }
        this.set('ranks', result.discord_ranks);
      }).catch(_ajaxError.popupAjaxError);
    },
    fetchChannels() {
      var params = "";
      if (this.searched_user_id.length > 0) {
        params = params + "?user_id=" + this.searched_user_id;
      }
      this.set('channels_loaded', false);
      (0, _ajax.ajax)("/discord/channels.json" + params).then(result => {
        this.set('channels', result.discord_channels);
        this.set('channels_loaded', true);
      }).catch(_ajaxError.popupAjaxError);
    },
    fetchMessages() {
      var params = "page=" + (this.current_page - 1).toString();
      if (this.filter_channel.length > 0) {
        params = params + "&channel=" + this.filter_channel;
      }
      if (this.searched_user_id.length > 0) {
        params = params + "&user_id=" + this.searched_user_id;
      }
      this.set('messages_loaded', false);
      (0, _ajax.ajax)("/discord/messages.json?" + params).then(result => {
        this.set('messages', result.discord_messages);
        this.set('stats', result.stats);
        this.set('messages_loaded', true);
      }).catch(_ajaxError.popupAjaxError);
    },
    actions: {
      filterChannel(channel_id) {
        this.set('current_page', 1);
        this.set('filter_channel', channel_id);
        this.fetchMessages();
      },
      nextMessagePage() {
        this.set('current_page', this.current_page + 1);
        this.fetchMessages();
      },
      prevMessagePage() {
        this.set('current_page', Math.max(this.current_page - 1, 1));
        this.fetchMessages();
      },
      onChangeSearchTermForUsername(username) {
        this.set("searched_username", username.length ? username : null);
        (0, _ajax.ajax)("/u/" + username + ".json").then(result => {
          this.set('searched_user_id', result.user.id.toString());
          (0, _ajax.ajax)("/discord/users.json?user_id=" + this.searched_user_id).then(result => {
            if (result.discord_users.length == 0) {
              this.set("searched_discord_username", "");
            } else {
              this.set("searched_discord_username", "@" + result.discord_users[0].tag);
            }
            this.fetchMessages();
            this.fetchChannels();
          }).catch(_ajaxError.popupAjaxError);
        }).catch(_ajaxError.popupAjaxError);
      }
    }
  });
});