define("discourse/plugins/discourse-discord-datastore/discourse/discord-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route('discord', {
      path: '/discord'
    });
    this.route('admin-discord', {
      path: '/admin/discord'
    });
  }
});